// Enum definitions
export enum ModeEnum {
    EDITING = "editing",
    EXPECTS = "expects",
    PROCESSING = "processing",
    OUTPUT = "output",
    UNKNOWN = "unknown",
}

export enum TypeEnum {
    INSTRUCTION = "instruction",
    NOTE = "note",
}

export enum StatusEnum {
    SUCCESS = "success",
    PENDING = "pending",
    RUNNING = "running",
    RESPONDING = "responding",
    ABORTED = "aborted",
    FAILED = "failed"
}

export enum KindEnum {
    SIMPLE = "simple",
    AUTOMATED = "automated",
    V1 = "v1"
}

export enum StageEnum {
    LIVE = "live",
    TEST = "test",
}

// Base BaseStep interface
export interface BaseStep {
    step_id?: string;
    description?: string;
    type: TypeEnum;
    mode: ModeEnum;
    partial: boolean;
    error?: string;
    logs: string[];
}

// Specific BaseStep types extending base BaseStep
export interface InstructionStep extends BaseStep {
    instruction: string;
    validation: string;
    message?: string;
    code?: string;
    final: boolean;
    mute: boolean;
    pinned: boolean;
    feedback?: string;
    computed_at?: number;
}

export interface NoteStep extends BaseStep {
    markdown: string;
}

// New interfaces for routine inputs matching the pydantic model
export interface RoutineRunInput {
    text: string;
    guidance: string;
    output_format: string;
    output_schema?: any;
}


// Updated Routine interface
export interface Routine {
    project_id: string;
    routine_id: string;
    task: string;
    name: string;
    description: string;
    kind: KindEnum;
    handbook_ids: string[];
    instructions: string;
    triggers: any[];
    inputs: RoutineRunInput;
    wheels: boolean;
    action_pack_ids: string[];
    steps: Array<InstructionStep | NoteStep>;
    stage: string;
    created_at: string;
    updated_at: string;
    persona_id: string;
    pinned: boolean;
}

// Updated RoutineRun interface
export interface RoutineRun {
    account_id?: string;
    project_id: string;
    routine_id: string;
    run_id: string;
    routine: Routine;
    state: string;
    start_at: string;
    end_at?: string;
    model?: string;
    artifact: string;
    scratchpad: string;
    output_format: string;
    version?: number;
    trigger: {
        name: string;
        type: string;
        context: Record<string, any>;
    };
    inputs: RoutineRunInput;
    persona_id: string;
    steps: Array<InstructionStep | NoteStep>;
    created_at: string;
    updated_at: string;
}

// Optionally, add a StepOutput interface matching the pydantic StepOutput:
export interface StepOutput {
    thoughts?: string | null;
    scratchpad?: string | null;
    artifact?: string | null;
    code?: string | null;
    message: string;
    final: boolean;
}

export type User = {
    account_id: string;
    name: string;
    email: string;
}

export interface Commands {
    instruct: string[];
    text: string[];
    answered: string[];
    edit: string[];
    question: string[];
}

export interface ApiKey {
    api_key: string;
    reveal: boolean;
    name: string;
}

export interface Usage {
    total_usage: {
        run_time: number;
        input_tokens: number;
        output_tokens: number;
        success: number;
        input_charges: number;
        output_charges: number;
    };
    daily_usage: Array<{
        year?: number;
        month: number;
        day: number;
        usage: {
            run_time: number;
            input_tokens: number;
            output_tokens: number;
            input_charges: number;
            output_charges: number;
        };
    }>;
}

export interface Credits {
    credits: number;
}

export interface Persona {
    persona_id: string;
    name: string;
    description: string;
    favorite: boolean;
    created_at: string;
    updated_at: string;
    content: any;
}


export interface Document {
    document_id: string;
    name: string;
    content: string;
    created_at: string;
    updated_at: string;
}

export interface Persona {
    persona_id: string;
    name: string;
    content: any;
    description: string;
    favorite: boolean;
    created_at: string;
    updated_at: string;
}

export interface Item {
    item_id?: string;
    name: string;
    type: string;
    source: string;
    description: string;
    created_at?: string
    updated_at?: string;
}


export interface InputVariable {
    title: string;
    description: string;
    type: string;
    value: any;
}

export interface ChatbotInputs {
    sources: Item[];
    mode: string;
    use_cached: boolean;
    variables: { [key: string]: InputVariable };
    description: string;
}

export interface BackgroundKnowledge {
    description: string;
    mode: ModeEnum;
    sources: Item[];
    use_cached: boolean;
}

export enum MessageType {
    EMAIL = "email",
    SLACK = "slack",
    UI = "ui"
}

export interface BaseMessage {
    message_id: string;
    message_type: MessageType;
    sender: string;
    content: string;
    role: string;
    attachments?: { [key: string]: any }[];
    timestamp: string;
    metadata: { [key: string]: any };
    error?: string;
    computed_at?: number;
    partial: boolean;
}

export interface EmailMessage extends BaseMessage {
    sender: string; // EmailStr in TypeScript
    recipients: string[]; // EmailStr[] in TypeScript
    subject: string;
    cc?: string[]; // EmailStr[] in TypeScript
    bcc?: string[]; // EmailStr[] in TypeScript
    importance: string;
}

export interface SlackMessage extends BaseMessage {
    channel_id: string;
    thread_ts?: string;
    reactions?: { [key: string]: any }[];
    edited?: { [key: string]: any };
}

export interface UIMessage extends BaseMessage {
    // No additional fields for UIMessage
}

export type Message = EmailMessage | SlackMessage | UIMessage;

export interface Chatbot {
    kind: KindEnum;
    project_id: string;
    chatbot_id: string;
    name: string;
    description: string;
    purpose: string;
    instructions: string;
    introduction: string;
    stage: StageEnum;
    shortlink: string;
    background_knowledge: BackgroundKnowledge;
    persona_id: string;
    action_packs: string[];
    pinned: boolean;
    created_at: string;
    updated_at: string;
    archived: boolean;
}

export interface Conversation {
    kind: KindEnum;
    project_id: string;
    chatbot_id: string;
    conversation_id: string;
    title: string;
    description: string;
    status: StatusEnum;
    inputs: ChatbotInputs;
    messages: Message[];
    persona_id: string;
    computed_at?: number;
    pinned: boolean;
    model_used?: string;
    start_at?: string;
    end_at?: string;
    run_time?: number;
    version?: number;
    current_time: string;
    created_at: string;
    updated_at: string;
    archived: boolean;
}


export interface ActionPack {
    action_pack_id: string;
    name: string;
    description: string;
    enabled: boolean;
    secret_type: string;
    created_at: string;
    updated_at: string;
}
