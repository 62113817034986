import React, { useState, useEffect } from "react";
import { RoutineRunInput } from "@/types";

// Updated props interface to reflect the new RoutineRunInput type and include instructions.
interface RoutineRunInputsComponentProps {
  inputs: RoutineRunInput;
  instructions?: string; // New prop to display routine instructions
}

export const RoutineRunInputs: React.FC<RoutineRunInputsComponentProps> = ({
  inputs,
}) => {
  const [formValues, setFormValues] = useState<RoutineRunInput>({
    ...inputs,
    guidance: inputs.guidance || "",
    output_format: inputs.output_format || "",
    text: inputs.text || "",
  });

  useEffect(() => {
    setFormValues({
      ...inputs,
      guidance: inputs.guidance || "",
      output_format: inputs.output_format || "",
      text: inputs.text || "",
    });
  }, [inputs]);

  return (
    <div className="space-y-4">
      <h2 className="text-sm font-semibold">Routine Inputs</h2>
      <div className="text-xs text-gray-500">Guidance: {inputs.guidance}</div>

      {/* <Input
        name="output_format"
        placeholder="Output Format"
        value={formValues.output_format}
        onChange={handleChange}
        readOnly={disableSaveButton} // Added readOnly if disabled
      /> */}
      {/* Replace the plain textarea with a shadcn Textarea */}
      <div>{formValues.text}</div>
    </div>
  );
};

export default RoutineRunInputs;
