import { useState, useEffect, useRef } from "react";
import { AIHero } from "@/components/common/ai-hero";
import { ResponsiveNav } from "@/components/common/responsive-nav";
import { UserNav } from "@/components/common/user-nav";
import { BackNav } from "@/components/common/back-nav";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { useParams, useNavigate } from "react-router-dom";
import {
  Routine,
  InstructionStep,
  NoteStep,
  RoutineRunInput,
  ModeEnum,
} from "@/types";
import { InstructionCell } from "@/components/pages/single_routine/components/instruction";
import { NotesCell } from "@/components/pages/single_routine/components/notes";
import { Loader2Icon, CopyIcon, TrashIcon } from "lucide-react";
import CanvasCell from "./components/canvas";
import { RoutineRunInputs } from "@/components/pages/single_routine/components/inputs";
import { fetchWithProgress } from "@/hooks/useFetchWithProgress";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";

const SingleRoutine = () => {
  const [routine, setRoutine] = useState<Routine>();
  const [loading, setLoading] = useState(true);
  const [error] = useState<string>("");
  const [, setElapsedTime] = useState(0);
  const { project_id, routine_id } = useParams<{
    project_id: string;
    routine_id: string;
  }>();
  const navigate = useNavigate();

  const [showCloneConfirmation, setShowCloneConfirmation] = useState(false);
  const [showArchiveConfirmation, setShowArchiveConfirmation] = useState(false);

  // -- Add new local state to store the routine run status and data
  const [routineRunStatus, setRoutineRunStatus] = useState<string>("");
  const [routineRun, setRoutineRun] = useState<any>(null);

  // -- If a new run is started, we want to update the UI in real-time
  const [isLoadingRun, setIsLoadingRun] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  // -- This is the ID of the currently active run (if any)
  const [currentRunId, setCurrentRunId] = useState<string | null>(null);

  useEffect(() => {
    fetchRoutine();

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routine_id]);

  // -- Whenever currentRunId changes, fetch the run status and start the loop
  useEffect(() => {
    if (!currentRunId) return;
    // -- Clear any old timeouts in case a new run starts
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }

    fetchRoutineRunStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRunId]);

  const fetchRoutine = async () => {
    try {
      const response = await fetchWithProgress(
        `/api/v1/projects/${project_id}/autonomous/routines/${routine_id}`,
        {
          method: "GET",
        },
        navigate
      );

      const data: Routine = await response.json();
      setRoutine(data);
      setLoading(false);
    } catch (error: any) {
      console.error("Failed to load the routine:", error);
      setLoading(false);
    }
  };

  // -- This function checks the state of the currently-running Routine Run
  const fetchRoutineRunStatus = async () => {
    if (!project_id || !routine_id || !currentRunId) return;
    try {
      const response = await fetchWithProgress(
        `/api/v1/projects/${project_id}/autonomous/routines/${routine_id}/routine_runs/${currentRunId}`,
        { method: "GET" },
        navigate,
        true,
        true,
        false
      );
      if (response.ok) {
        const data = await response.json();
        if (data.state === "failed") {
          var errorStep = data.steps.find((step: any) => step.error.length);
          console.log("Routine run failed", errorStep);
          if (errorStep) {
            const errorStepIndex = data.steps.indexOf(errorStep);
            for (var i = errorStepIndex; i < data.steps.length; i++) {
              data.steps[i].mode = "unknown";
            }
          }
        }
        setRoutineRunStatus(data.state || "");
        setRoutineRun(data);

        // -- If it's "running" or "pending" or something that ends in "ing"
        //    keep polling until it changes to a finished state
        if (data.state?.endsWith("ing")) {
          timeoutRef.current = setTimeout(fetchRoutineRunStatus, 1000);
        }
      }
    } catch (error) {
      console.error("Failed to fetch routine run status:", error);
    }
  };

  // -- Start a new run for the routine
  const startRoutineRun = async () => {
    if (!project_id || !routine_id) return;
    setIsLoadingRun(true);

    // -- Clear any previous run tracking
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
    setRoutineRunStatus("");
    setRoutineRun(null);

    try {
      const response = await fetchWithProgress(
        `/api/v1/projects/${project_id}/autonomous/routines/${routine_id}/routine_runs`,
        {
          method: "POST",
          body: JSON.stringify({}),
        },
        navigate
      );
      const data = await response.json();

      // -- This sets a new run ID. The useEffect above will automatically
      //    kick off fetchRoutineRunStatus when currentRunId changes.
      setCurrentRunId(data.run_id);
    } catch (error) {
      console.error("Failed to start routine run:", error);
    } finally {
      setIsLoadingRun(false);
    }
  };

  const updateRoutine = async () => {
    if (!routine) return;
    try {
      setElapsedTime(0);
      const routineCopy = JSON.parse(JSON.stringify(routine));
      delete routineCopy._id;
      delete routineCopy.project_id;
      delete routineCopy.routine_id;
      for (const step of routineCopy.steps) {
        step.mode = ModeEnum.OUTPUT;
      }

      const response = await fetchWithProgress(
        `/api/v1/projects/${project_id}/autonomous/routines/${routine.routine_id}`,
        {
          method: "PATCH",
          body: JSON.stringify(routineCopy),
        },
        navigate
      );

      const updatedRoutine = await response.json();
      setRoutine(updatedRoutine);
    } catch (error: any) {
      console.error("Failed to update the routine:", error);
    }
  };

  const updateStep = async (index: number, updatedStep: any) => {
    if (!routine) return;
    try {
      setElapsedTime(0);
      const updatedRoutine = { ...routine };
      updatedRoutine.steps[index] = updatedStep;
      setRoutine(updatedRoutine);
      await updateRoutine();
    } catch (error: any) {
      console.error("Failed to update the step:", error);
    }
  };

  const handleCloneClick = () => {
    setShowCloneConfirmation(true);
  };

  const handleArchiveClick = () => {
    setShowArchiveConfirmation(true);
  };

  const confirmClone = async () => {
    setShowCloneConfirmation(false);
    await cloneRoutine();
  };

  const confirmArchive = async () => {
    setShowArchiveConfirmation(false);
    await archiveRoutine();
  };

  const cloneRoutine = async () => {
    if (!routine) return;
    try {
      const newRoutineData = JSON.parse(JSON.stringify(routine));
      delete newRoutineData.project_id;
      delete newRoutineData.routine_id;
      delete newRoutineData._id;
      delete newRoutineData.status;
      newRoutineData.name = `${routine.name}`;

      const response = await fetchWithProgress(
        `/api/v1/projects/${project_id}/autonomous/routines`,
        {
          method: "POST",
          body: JSON.stringify(newRoutineData),
        },
        navigate
      );

      const newRoutine = await response.json();
      navigate(`/projects/${project_id}/routines/${newRoutine.routine_id}`);
    } catch (error: any) {
      console.error("Failed to clone routine", error);
    }
  };

  const archiveRoutine = async () => {
    if (!routine) return;
    try {
      await fetchWithProgress(
        `/api/v1/projects/${project_id}/autonomous/routines/${routine.routine_id}`,
        {
          method: "DELETE",
        },
        navigate
      );
      navigate(`/projects/${project_id}/routines`);
    } catch (error: any) {
      console.error("Failed to archive routine", error);
      if (error.response && error.response.status >= 500) {
        console.error(
          "Server Error:",
          error.response.status,
          error.response.text
        );
        navigate("/5xx");
      }
    }
  };

  // -- Abort the currently active run
  const abort = async () => {
    if (!project_id || !routine_id || !currentRunId) return;
    try {
      const response = await fetchWithProgress(
        `/api/v1/projects/${project_id}/autonomous/routines/${routine_id}/routine_runs/${currentRunId}/abort`,
        {
          method: "POST",
        },
        navigate
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      // -- After abort, fetch the new run status to update UI
      fetchRoutineRunStatus();
    } catch (error: any) {
      console.error("Failed to abort the routine run:", error);
    }
  };

  const deleteStep = async (index: number, step_id: string) => {
    if (!routine) return;
    if (!step_id) {
      try {
        const newSteps = [...routine.steps];
        newSteps.splice(index, 1);
        setRoutine({ ...routine, steps: newSteps });
      } catch (error: any) {
        console.error("Failed to delete the step:", error);
      }
    } else {
      try {
        routine.steps = routine.steps.filter(
          (step) => step.step_id !== step_id
        );
        setTimeout(() => {
          updateRoutine();
        }, 0);
      } catch (error: any) {
        console.error("Failed to delete the step:", error);
      }
    }
  };

  // const handleAddInstruction = async (index: number) => {
  //   if (!routine) return;
  //   const newSteps = [...routine.steps];
  //   newSteps.splice(index, 0, {
  //     type: "instruction",
  //     instruction: "",
  //     mode: ModeEnum.EDITING,
  //     markdown: "(will be available after the step is run)",
  //     final: false,
  //     mute: false,
  //     pinned: false,
  //     partial: false,
  //   } as InstructionStep);

  //   setRoutine({ ...routine, steps: newSteps });
  // };

  // const handleAddNote = async (index: number) => {
  //   if (!routine) return;
  //   const newSteps = [...routine.steps];
  //   newSteps.splice(index, 0, {
  //     type: "note",
  //     markdown: "",
  //     mode: ModeEnum.EDITING,
  //   } as NoteStep);
  //   routine.steps = newSteps;
  // };

  // const handlePersonaSelect = async (personaId: string) => {
  //   if (!routine) return;
  //   routine.persona_id = personaId;
  //   await updateRoutine();
  // };

  // const updateStage = async (stage: Stage) => {
  //   if (!routine) return;
  //   routine.stage = stage;
  //   await updateRoutine();
  // };

  const updateRoutineRunInputs = (newInputs: RoutineRunInput) => {
    if (!routine) return;
    routine.inputs = newInputs;
    updateRoutine();
  };

  const navigateToRuns = () => {
    if (!project_id || !routine_id) return;
    navigate(`/projects/${project_id}/routines/${routine_id}/routine_runs`);
  };

  // Removed replaceWithVariables function
  // const replaceWithVariables = (content: string) => { ... }

  // Updated to use routine values directly
  const displayName = routine ? routine.name : "";
  const displayTask = routine?.task || "";

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="flex flex-row ">
          <Loader2Icon className="mr-4 animate-spin" /> Loading...
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="flex flex-row ">Error: {error}</div>
      </div>
    );
  }

  if (!routine) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="flex flex-row ">No routine found</div>
      </div>
    );
  }

  return (
    <>
      <header className="flex h-16 items-center justify-between border-b bg-background px-6">
        <nav className="hidden md:flex space-x-6">
          <AIHero />
          <BackNav className="mx-6" parent="routines" />
        </nav>
        <ResponsiveNav />
        <div className="flex-grow flex justify-center items-center space-x-1">
          <Button
            onClick={handleCloneClick}
            variant="secondary"
            className="bg-white border-none shadow-none"
          >
            <CopyIcon className="w-4 h-4" />
          </Button>
          <Button
            onClick={handleArchiveClick}
            variant="secondary"
            className="bg-white border-none shadow-none"
          >
            <TrashIcon className="w-4 h-4" />
          </Button>
        </div>
        <div className="ml-auto flex items-center space-x-4">
          {routineRunStatus && (
            <Badge variant="outline">{routineRunStatus}</Badge>
          )}
          {routineRunStatus?.endsWith("ing") ? (
            <Button variant="destructive" onClick={abort}>
              Abort Run
            </Button>
          ) : (
            <Button onClick={startRoutineRun} disabled={isLoadingRun}>
              Test Routine
            </Button>
          )}
          <Button
            variant="outline"
            className="text-sm"
            onClick={navigateToRuns}
          >
            See all runs
          </Button>
          <UserNav />
        </div>
      </header>

      <main className="flex flex-grow overflow-x-hidden px-4 grid grid-cols-5">
        <div className="col-span-2 p-4">
          <div className="items-center justify-between grid w-full">
            {displayTask && (
              <>
                <h3
                  className="text-sm"
                  dangerouslySetInnerHTML={{ __html: displayTask }}
                ></h3>
              </>
            )}
            <div className="flex items-center space-x-4 text-2xl font-semibold w-full my-2">
              <span dangerouslySetInnerHTML={{ __html: displayName }}></span>
            </div>
          </div>
          <div className="w-full">
            <div className="text-xs bg-gray-100 p-2 my-2">
              {routine.instructions}
            </div>

            <RoutineRunInputs
              inputs={routine.inputs}
              updateInputs={updateRoutineRunInputs}
            />

            <h2 className="my-4 text-sm font-semibold">PLAN</h2>
            {routine.steps.map((step, index) => (
              <div className="mb-2" id={String(index)} key={index}>
                {step.type === "instruction" ? (
                  <>
                    <InstructionCell
                      step={step as InstructionStep}
                      updateStep={(updatedStep) =>
                        updateStep(index, updatedStep)
                      }
                      deleteStep={(originalStep) =>
                        deleteStep(index, originalStep.step_id || "")
                      }
                      routineRunStep={routineRun?.steps?.[index]}
                    />
                  </>
                ) : step.type === "note" ? (
                  <>
                    <NotesCell
                      step={step as NoteStep}
                      updateStep={(updatedStep) =>
                        updateStep(index, updatedStep)
                      }
                      deleteStep={(originalStep) =>
                        deleteStep(index, originalStep.step_id || "")
                      }
                      routineRunStep={routineRun?.steps?.[index]}
                    />
                  </>
                ) : null}
              </div>
            ))}
          </div>
        </div>
        <div className="col-span-3 p-4 border-l">
          {routineRun?.artifact ? (
            <CanvasCell canvas={routineRun.artifact} />
          ) : (
            <>
              <div className="text-muted-foreground text-center h-full flex items-center justify-center">
                Output of the Routine appears here. Click 'Test Run' above to
                run the Agent routine with default values.
              </div>
            </>
          )}
        </div>
      </main>

      <AlertDialog
        open={showCloneConfirmation}
        onOpenChange={setShowCloneConfirmation}
      >
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Clone Routine</AlertDialogTitle>
            <AlertDialogDescription>
              Are you sure you want to clone this routine? This will create a
              new copy with the same settings.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={confirmClone}>Clone</AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <AlertDialog
        open={showArchiveConfirmation}
        onOpenChange={setShowArchiveConfirmation}
      >
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Archive Routine</AlertDialogTitle>
            <AlertDialogDescription>
              Are you sure you want to archive this routine? This action cannot
              be undone.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={confirmArchive}>
              Archive
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default SingleRoutine;
