import { useState, useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { AIHero } from "@/components/common/ai-hero";
import { MainNav } from "@/components/common/main-nav";
import { ResponsiveNav } from "@/components/common/responsive-nav";
import { UserNav } from "@/components/common/user-nav";
import {
  Pin,
  PinOff,
  MoreHorizontal,
  Pencil,
  ChevronLeft,
  ChevronRight,
} from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuItem,
} from "@/components/ui/dropdown-menu";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Input } from "@/components/ui/input";
import { AutosizeTextarea } from "@/components/ui/autosize-textarea";
import { Label } from "@/components/ui/label";
import { useToast } from "@/components/ui/use-toast";
import { fetchWithProgress } from "@/hooks/useFetchWithProgress";
import { Routine, RoutineRunInput } from "@/types";
import RoutineRunInputs from "@/components/pages/routines/components/inputs";

const PAGE_SIZES = [10, 20, 50, 100];
const DEFAULT_PAGE_SIZE = 10;

const Routines = () => {
  const { project_id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [routines, setRoutines] = useState<Routine[]>([]);
  const [totalRoutines, setTotalRoutines] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const navigate = useNavigate();
  const { toast } = useToast();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [editingRoutine, setEditingRoutine] = useState<Routine | undefined>();
  const [routineToDelete, setRoutineToDelete] = useState("");
  const [isLaunchDialogOpen, setIsLaunchDialogOpen] = useState(false);
  const [selectedRoutine, setSelectedRoutine] = useState<Routine | null>(null);
  const [inputs, setInputs] = useState<RoutineRunInput>({
    text: "",
    guidance: "",
    output_format: "markdown",
    output_schema: "",
  });

  const pageSize = parseInt(
    searchParams.get("limit") || String(DEFAULT_PAGE_SIZE)
  );
  const currentPage = parseInt(searchParams.get("page") || "1");
  const skip = (currentPage - 1) * pageSize;

  useEffect(() => {
    fetchRoutines();
  }, [currentPage, pageSize]);

  const fetchRoutines = async () => {
    setIsFetching(true);
    try {
      const response = await fetchWithProgress(
        `/api/v1/projects/${project_id}/autonomous/routines?skip=${skip}&limit=${pageSize}`,
        { method: "GET" },
        navigate
      );
      if (response) {
        const data = await response.json();
        setRoutines(data.routines || []);
        setTotalRoutines(data.pages * pageSize || 0);
      }
    } catch (error) {
      console.error("Failed to fetch routines", error);
    }
    setIsFetching(false);
  };

  const handlePin = async (routineId: string, isPinned: boolean) => {
    try {
      await fetchWithProgress(
        `/api/v1/projects/${project_id}/autonomous/routines/${routineId}/settings`,
        {
          method: "POST",
          body: JSON.stringify({ pinned: !isPinned }),
        },
        navigate
      );
      fetchRoutines();
    } catch (error) {
      console.error("Failed to update pin status", error);
    }
  };

  const handleDelete = async () => {
    try {
      await fetchWithProgress(
        `/api/v1/projects/${project_id}/autonomous/routines/${routineToDelete}`,
        { method: "DELETE" },
        navigate
      );
      setIsDeleteDialogOpen(false);
      fetchRoutines();
      toast({ description: "Routine deleted successfully." });
    } catch (error) {
      toast({
        description: "Failed to delete routine.",
        variant: "destructive",
      });
    }
  };

  const handleUpdate = async () => {
    if (!editingRoutine) return;
    try {
      await fetchWithProgress(
        `/api/v1/projects/${project_id}/autonomous/routines/${editingRoutine.routine_id}`,
        {
          method: "PATCH",
          body: JSON.stringify({
            name: editingRoutine.name,
            description: editingRoutine.description,
          }),
        },
        navigate
      );
      setIsEditDialogOpen(false);
      fetchRoutines();
      toast({ description: "Routine updated successfully." });
    } catch (error) {
      toast({
        description: "Failed to update routine.",
        variant: "destructive",
      });
    }
  };

  const handlePageChange = (newPage: number) => {
    searchParams.set("page", newPage.toString());
    setSearchParams(searchParams);
  };

  const handlePageSizeChange = (newSize: string) => {
    searchParams.set("limit", newSize);
    searchParams.set("page", "1");
    setSearchParams(searchParams);
  };

  const totalPages = Math.max(1, Math.ceil(totalRoutines / pageSize));

  const handleLaunch = async () => {
    if (!selectedRoutine) return;
    console.log("Launching inputs", inputs);

    try {
      const response = await fetchWithProgress(
        `/api/v1/projects/${project_id}/autonomous/routines/${selectedRoutine.routine_id}/routine_runs`,
        {
          method: "POST",
          body: JSON.stringify({ inputs }), // Pass updated inputs
        },
        navigate
      );

      if (response) {
        const data = await response.json();
        setIsLaunchDialogOpen(false);
        navigate(
          `/projects/${project_id}/routines/${selectedRoutine.routine_id}/routine_runs/${data.run_id}`
        );
        toast({ description: "Routine launched successfully." });
      }
    } catch (error) {
      toast({
        description: "Failed to launch routine.",
        variant: "destructive",
      });
    }
  };

  const handleInputsUpdate = (newInputs: RoutineRunInput) => {
    setInputs(JSON.parse(JSON.stringify(newInputs)));
    console.log("Updating inputs in parent:", inputs);
  };

  return (
    <>
      <header className="flex h-16 items-center justify-between border-b bg-background px-6">
        <nav className="hidden md:flex space-x-6">
          <AIHero />
          <MainNav />
        </nav>
        <ResponsiveNav />
        <UserNav />
      </header>

      <main className="container p-6">
        <div className="flex items-center justify-between mb-6">
          <h1 className="text-3xl font-semibold">Routines</h1>
        </div>

        <div className="rounded-md border">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Name</TableHead>
                <TableHead>Task</TableHead>
                <TableHead></TableHead>
                <TableHead className="text-right">Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {isFetching ? (
                <TableRow>
                  <TableCell colSpan={4} className="text-center">
                    Loading routines...
                  </TableCell>
                </TableRow>
              ) : routines.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={4} className="text-center">
                    No routines found. Create your first routine to get started.
                  </TableCell>
                </TableRow>
              ) : (
                routines.map((routine) => (
                  <TableRow
                    key={routine.routine_id}
                    className="cursor-pointer"
                    onClick={() =>
                      navigate(
                        `/projects/${project_id}/routines/${routine.routine_id}`
                      )
                    }
                  >
                    <TableCell className="font-medium">
                      {routine.name}
                    </TableCell>
                    <TableCell className="max-w-md truncate">
                      {routine.task}
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="default"
                        size="sm"
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedRoutine(routine);
                          setInputs(
                            JSON.parse(JSON.stringify(routine.inputs || []))
                          );
                          setIsLaunchDialogOpen(true);
                        }}
                      >
                        Launch
                      </Button>
                    </TableCell>
                    <TableCell className="text-right">
                      <div
                        className="flex justify-end space-x-2"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <Button
                          variant="ghost"
                          size="icon"
                          onClick={() =>
                            handlePin(routine.routine_id, routine.pinned)
                          }
                        >
                          {routine.pinned ? (
                            <Pin className="h-4 w-4 text-primary" />
                          ) : (
                            <PinOff className="h-4 w-4" />
                          )}
                        </Button>
                        <Button
                          variant="ghost"
                          size="icon"
                          onClick={() => {
                            setEditingRoutine(routine);
                            setIsEditDialogOpen(true);
                          }}
                        >
                          <Pencil className="h-4 w-4" />
                        </Button>
                        <DropdownMenu>
                          <DropdownMenuTrigger asChild>
                            <Button variant="ghost" size="icon">
                              <MoreHorizontal className="h-4 w-4" />
                            </Button>
                          </DropdownMenuTrigger>
                          <DropdownMenuContent align="end">
                            <DropdownMenuLabel>Actions</DropdownMenuLabel>
                            <DropdownMenuItem
                              onClick={() => {
                                setRoutineToDelete(routine.routine_id);
                                setIsDeleteDialogOpen(true);
                              }}
                            >
                              Delete
                            </DropdownMenuItem>
                          </DropdownMenuContent>
                        </DropdownMenu>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </div>

        <div className="flex items-center justify-between space-x-2 py-4">
          <div className="flex items-center space-x-2">
            <p className="text-sm text-muted-foreground">
              Showing {Math.min(skip + 1, totalRoutines)}-
              {Math.min(skip + pageSize, totalRoutines)} of {totalRoutines}{" "}
              results
            </p>
            <Select
              value={pageSize.toString()}
              onValueChange={handlePageSizeChange}
            >
              <SelectTrigger className="h-8 w-[70px]">
                <SelectValue />
              </SelectTrigger>
              <SelectContent side="top">
                {PAGE_SIZES.map((size) => (
                  <SelectItem key={size} value={size.toString()}>
                    {size}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <div className="flex items-center space-x-2">
            <Button
              variant="outline"
              size="icon"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <ChevronLeft className="h-4 w-4" />
            </Button>
            <div className="flex items-center">
              <span className="text-sm font-medium">
                Page {currentPage} of {totalPages}
              </span>
            </div>
            <Button
              variant="outline"
              size="icon"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage >= totalPages}
            >
              <ChevronRight className="h-4 w-4" />
            </Button>
          </div>
        </div>

        <Dialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Delete Routine</DialogTitle>
              <DialogDescription>
                Are you sure you want to delete this routine? This action cannot
                be undone.
              </DialogDescription>
            </DialogHeader>
            <DialogFooter>
              <Button
                variant="outline"
                onClick={() => setIsDeleteDialogOpen(false)}
              >
                Cancel
              </Button>
              <Button variant="destructive" onClick={handleDelete}>
                Delete
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>

        <Dialog open={isEditDialogOpen} onOpenChange={setIsEditDialogOpen}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Edit Routine</DialogTitle>
              <DialogDescription>
                Update the details of your routine.
              </DialogDescription>
            </DialogHeader>
            <div className="grid gap-4 py-4">
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="name" className="text-right">
                  Name
                </Label>
                <Input
                  id="name"
                  value={editingRoutine?.name || ""}
                  onChange={(e) =>
                    setEditingRoutine((prev) =>
                      prev ? { ...prev, name: e.target.value } : undefined
                    )
                  }
                  className="col-span-3"
                />
              </div>
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="description" className="text-right">
                  Description
                </Label>
                <AutosizeTextarea
                  id="description"
                  value={editingRoutine?.description || ""}
                  onChange={(e) =>
                    setEditingRoutine((prev) =>
                      prev ? { ...prev, description: e.target.value } : prev
                    )
                  }
                  className="col-span-3"
                />
              </div>
            </div>
            <DialogFooter>
              <Button
                variant="outline"
                onClick={() => setIsEditDialogOpen(false)}
              >
                Cancel
              </Button>
              <Button onClick={handleUpdate}>Save Changes</Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>

        <Dialog open={isLaunchDialogOpen} onOpenChange={setIsLaunchDialogOpen}>
          <DialogContent className="max-w-2xl">
            <DialogHeader>
              <DialogTitle>Launch Routine: {selectedRoutine?.name}</DialogTitle>
              <DialogDescription>
                Configure the variables for this routine run.
              </DialogDescription>
            </DialogHeader>
            <div className="py-4">
              <RoutineRunInputs
                inputs={inputs}
                updateInputs={(newInputs: any) => handleInputsUpdate(newInputs)}
              />
            </div>
            <DialogFooter>
              <Button
                variant="outline"
                onClick={() => {
                  setIsLaunchDialogOpen(false);
                  setSelectedRoutine(null);
                  setInputs({
                    text: "",
                    guidance: "",
                    output_format: "markdown",
                    output_schema: "",
                  });
                }}
              >
                Cancel
              </Button>
              <Button onClick={handleLaunch}>Launch Routine</Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </main>
    </>
  );
};

export default Routines;
