import React, { useState, useEffect } from "react";
import { RoutineRunInput } from "@/types";
import { AutosizeTextarea } from "@/components/ui/autosize-textarea";

// Updated props interface to reflect the new RoutineRunInput type and include instructions.
interface RoutineRunInputsComponentProps {
  inputs: RoutineRunInput;
  updateInputs: CallableFunction;
  disableSaveButton?: boolean; // New prop added
  instructions?: string; // New prop to display routine instructions
}

export const RoutineRunInputs: React.FC<RoutineRunInputsComponentProps> = ({
  inputs,
  updateInputs,
  disableSaveButton,
}) => {
  const [formValues, setFormValues] = useState<RoutineRunInput>({
    ...inputs,
    guidance: inputs.guidance || "",
    output_format: inputs.output_format || "",
    text: inputs.text || "",
  });

  useEffect(() => {
    setFormValues({
      ...inputs,
      guidance: inputs.guidance || "",
      output_format: inputs.output_format || "",
      text: inputs.text || "",
    });
  }, [inputs]);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const updatedValues = { ...formValues, [e.target.name]: e.target.value };
    setFormValues(updatedValues);
    updateInputs(updatedValues);
  };

  return (
    <div className="space-y-4">
      <h2 className="text-sm font-semibold">Routine Inputs</h2>
      <div className="text-xs text-gray-500">Guidance: {inputs.guidance}</div>

      <AutosizeTextarea
        name="text"
        placeholder="Text"
        value={formValues.text}
        onChange={handleChange}
        readOnly={disableSaveButton}
      />
    </div>
  );
};

export default RoutineRunInputs;
